import React, { useEffect, useState } from "react";

export function Register() {

    const [ pilotName, setPilotName ] = useState("");
    const [ emailAddress, setEmailAddress ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ message, setMessage ] = useState("");

    const [ complete, setComplete ] = useState(false);

    async function doRegister() {

        if (password !== confirmPassword) {
            setMessage("Passwords must match");
            return;
        }

        if (password.length < 10) {
            setMessage("Password must be more than 10 characters");
            return;
        }

        if (emailAddress.indexOf('@') < 1) {
            setMessage("Email address must be valid");
            return;
        }

        if (pilotName.length < 4 || pilotName.length > 64) {
            setMessage("Pilot name must be between 4 and 64 characters");
            return;
        }

        const result = await fetch(process.env.REACT_APP_API_HOST + '/account/create', {
            body: JSON.stringify({
                PlayerName: pilotName,
                EmailAddress: emailAddress,
                Password: password
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (result.ok) {
            setComplete(true);
        }

    }

    useEffect(() => {

    }, [password, confirmPassword, message]);

    if (process.env.REACT_APP_REGISTER_ENABLED !== "true") {
        return <div className="register-page">
            <p className="para--featured">
                VIRTOPS is currently under construction. Check the discord for more information about alpha
                testing or our release date.
            </p>
        </div>
    }

    return <>
        <div className="register-page">
            <p>
                Register for a VIRTOPS account. Forgot your password? Tough luck, that hasn't
                been implemented yet.
            </p>
            { !complete &&
            <form onSubmit={doRegister} className="form">
                <p className="para para--warn">{message}</p>
                <input className="form__input" placeholder="Pilot Name"
                       onChange={(evt) => { setPilotName(evt.target.value)}} />
                <input className="form__input" placeholder="Email Address"
                       onChange={(evt) => { setEmailAddress(evt.target.value)}} />
                <input className="form__input" type="password" placeholder="Password"
                       onChange={(evt) => { setPassword(evt.target.value)}} />
                <input className={ password === confirmPassword || confirmPassword === "" ? "form__input" : "form__input form__input--invalid" } type="password" placeholder="Retype Password"
                       onChange={(evt) => { setConfirmPassword(evt.target.value)}} />
                <input className="form__input" type="submit" value="REGISTER" onClick={async (evt) => { evt.preventDefault(); await doRegister(); }} />
            </form> }
            { complete && <div>
                <p className="para para--featured">
                    Registration complete! Check your email inbox for a confirmation link.
                </p>
            </div>
            }
        </div>
    </>;
}