import React, { useCallback, useState } from "react";
import { useCookies } from "react-cookie";

export interface UserContextType {
    LoggedIn: boolean;
    PilotName: string;
    WebKey: string;
    ApiKey: string;

    Login(pilotName: string, webKey: string, apiKey: string): void;
    Logout(): void;
}

export const UserContext = React.createContext<UserContextType>({
    LoggedIn: false,
    PilotName: "",
    WebKey: "",
    ApiKey: "",
    Login(pilotName: string, webKey: string, apiKey: string) {},
    Logout() {}
});

export const CreateUserContext = (): UserContextType => {

    const [ loggedIn, setLoggedIn ] = useState(false);
    const [ pilotName, setPilotName ] = useState("");
    const [ webKey, setWebKey ] = useState("");
    const [ apiKey, setApiKey ] = useState("");
    const [, , removeCookie] = useCookies(['usercreds']);

    const login = useCallback((pilotName: string, webKey: string, apiKey: string) => {
        setLoggedIn(true);
        setPilotName(pilotName);
        setWebKey(webKey);
        setApiKey(apiKey);
    }, []);

    const logout = useCallback(() => {
        setLoggedIn(false);
        setPilotName("");
        setWebKey("");
        setApiKey("");

        removeCookie('usercreds');

    }, [removeCookie]);

    return {
        LoggedIn: loggedIn,
        PilotName: pilotName,
        WebKey: webKey,
        ApiKey: apiKey,
        Login: login,
        Logout: logout
    }
}
