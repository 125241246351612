import React, { useContext, MouseEvent } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";

export function Header() {

    const userContext = useContext(UserContext);

    async function doLogout(event: MouseEvent) {
        event.preventDefault();
        userContext.Logout();
    }

    if (window.location.pathname.startsWith('/efb'))
        return null;

    return <>
        <header className="header">
            <Link className="header__link link logo" to="/"><span>&lt;VIRTOPS</span></Link>
             {/*<Link className="header__link link" to="/map">MAP</Link>*/}
            {userContext.LoggedIn && <Link className="header__link link" to="/dashboard">DASHBOARD</Link>}
            {userContext.LoggedIn && <Link className="header__link link link--featured" target="virtops_efb_window" to="/efb">APP</Link>}
            {!userContext.LoggedIn && <Link className="header__link link" to="/login">LOGIN</Link>}
            {!userContext.LoggedIn && <Link className="header__link link link--featured" to="/register">REGISTER&gt;</Link>}
            {userContext.LoggedIn && <a className="header__link link" onClick={doLogout} href="#">LOGOUT</a>}
            <a className="header__link link link--featured-2" href="https://discord.gg/kwNRbzm">DISCORD</a>
        </header>
    </>;
}