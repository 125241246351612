import React, { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

interface LoginResponse
{
    pilotName: string;
    apiKey: string;
    webKey: string;
}

export function Login()
{
    const [ emailAddress, setEmailAddress ] = useState("kate.reidy.0@gmail.com");
    const [ password, setPassword ] = useState("testpassword");
    const [ , setCookie] = useCookies(['usercreds']);

    const userContext = useContext(UserContext);
    const history = useHistory();

    async function doLogin() {
        const result = await fetch(process.env.REACT_APP_API_HOST + '/account/login', {
            body: JSON.stringify({
                EmailAddress: emailAddress,
                Password: password
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (result.ok) {

            const loginResponse = await result.json() as LoginResponse;

            console.log(loginResponse);
            userContext.Login(loginResponse.pilotName, loginResponse.webKey, loginResponse.apiKey);

            setCookie('usercreds', JSON.stringify(loginResponse), {
                maxAge: 60 * 60 * 24 * 7,
                sameSite: 'strict',
                path: '/'
            });

            history.push('/dashboard');
        }
    }

    return <>
        <div className="login-page">
            <p>Don't have an account? <Link className="link" to="/register">Create an account here</Link></p>
            <form onSubmit={doLogin} className="form">
                <input className="form__input" type="text" value={emailAddress} placeholder="Email Address"
                       onChange={(evt) => { setEmailAddress(evt.target.value)}} />
                <input className="form__input" type="password" value={password} placeholder="Password"
                       onChange={(evt) => { setPassword(evt.target.value) }} />
                <input className="form__input" type="submit" value="LOGIN"
                       onClick={async (evt) => { evt.preventDefault(); await doLogin(); }} />
            </form>
        </div>
    </>
}