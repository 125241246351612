import React from "react";
import { Link } from "react-router-dom";

export function Footer() {

    if (window.location.pathname.startsWith('/efb'))
        return null;

    return <div className="footer">
        {/*<Link className="link" to="/privacy-policy">PRIVACY POLICY</Link>,*/}
        &lt;VIRTOPS &copy; 2020 KATE REIDY
    </div>
}