import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useHistory } from "react-router-dom";

interface FlightSummaryFlight {
    id: string;
    arriveIcao: string;
    arriveName: string;
    departIcao: string;
    departName: string;
    statusText: string;
    isActive: boolean;
    start: string;
    end: string;
}

interface FlightSummaryResponse {
    flights: FlightSummaryFlight[];
}

export function Dashboard() {

    const userContext = useContext(UserContext);
    const history = useHistory();

    const [flightData, setFlightData] = useState<FlightSummaryResponse>();

    useEffect(() => {
        if (!userContext.LoggedIn)
        {
            history.push('/');
        }
    }, [history, userContext.LoggedIn]);

    const getNewFlightData = async () => {
        const result = await fetch(process.env.REACT_APP_API_HOST + "/flightsummary", {
            headers: {
                'Content-Type': 'application/json',
                'X-VirtOps-WEB-Key': userContext.WebKey
            },
            method: 'GET'
        });

        const data = await result.json();

        setFlightData(data as FlightSummaryResponse);
    }

    const refreshFlightHistory = async () => {
        setFlightData({ flights: []});
        await getNewFlightData();
    }

    useEffect(() => {
        refreshFlightHistory().then(() => {});
    }, []);

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const formatDate = (dateStr: string): string => {

        const date = new Date(dateStr);

        if (date.getUTCFullYear() < 2000) {
            return '----';
        }

        const hours = date.getHours();
        const hoursStr = hours < 10 ? '0' + hours.toString() : hours.toString();

        const minutes = date.getMinutes();
        const minutesStr = minutes < 10 ? '0' + minutes.toString() : minutes.toString();

        return months[date.getMonth()] + "-" + date.getDate() + ' @ ' + hoursStr + ':' + minutesStr;
    }

    const downloadUrl = process.env.REACT_APP_API_HOST + "/connect/download?key=" + userContext.ApiKey;

    return <>
        <div>
            <h1>Dashboard</h1>
        </div>
        <div className="dashboard-page">
            <a className="link link--featured" href={downloadUrl}>Download CONNECT app</a>
        </div>
        <div>
            <h2>Flight History</h2>
            <button className="refresh-button" onClick={refreshFlightHistory} title="Refresh list">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M13.5 2c-5.629 0-10.212 4.436-10.475 10h-3.025l4.537 5.917 4.463-5.917h-2.975c.26-3.902 3.508-7 7.475-7 4.136 0 7.5 3.364 7.5 7.5s-3.364 7.5-7.5 7.5c-2.381 0-4.502-1.119-5.876-2.854l-1.847 2.449c1.919 2.088 4.664 3.405 7.723 3.405 5.798 0 10.5-4.702 10.5-10.5s-4.702-10.5-10.5-10.5z"/></svg>
                REFRESH
            </button>

            <table className="table">
                <thead>
                    <tr>
                        <td>From</td>
                        <td>To</td>
                        <td>Status</td>
                        <td>Start</td>
                        <td>End</td>
                    </tr>
                </thead>
                <tbody>
                    {flightData?.flights.map(flight =>
                        <tr key={flight.id}>
                            <td title={flight.departName}>{flight.departIcao}</td>
                            <td title={flight.arriveName}>{flight.arriveIcao}</td>
                            <td>{flight.statusText}</td>
                            <td>{formatDate(flight.start)}</td>
                            <td>{formatDate(flight.end)}</td>
                        </tr>)}
                </tbody>
            </table>
        </div>
        </>
}