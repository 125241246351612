import React, { useEffect, useState } from "react";
import queryString from "query-string";

export enum VerifyState {
    Verifying,
    Verified,
    Errored
}

export function Verify() {

    const [ verified, setVerified ] = useState<VerifyState>(VerifyState.Verifying);

    useEffect(() => {

        const params = queryString.parse(document.location.search);

        fetch(process.env.REACT_APP_API_HOST + '/account/verify', {
            body: JSON.stringify({
                EmailAddress: params.email,
                AccountId: params.accountId
            }),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        }).then(() => {
            setVerified(VerifyState.Verified);
        }).catch(() => {
            setVerified(VerifyState.Errored);
        })

    }, []);

    switch (verified) {
        case VerifyState.Errored:
            return <p>An error occurred! Please try again later.</p>;
        case VerifyState.Verified:
            return <p>Thanks for verifying! Happy flying!</p>
        case VerifyState.Verifying:
            return <p>Verifying your account...</p>
        default:
            return <p>Something has gone wrong.</p>
    }
}