import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, useRouteMatch } from "react-router-dom";
import { UserContext } from "./context/UserContext";

interface ActiveFlightStatus
{
    currentLat: number;
    currentLong: number;
    fromIcao: string;
    toIcao: string;
    flightStatus: number;
    flightStatusText: string;
    trueHeading: number;
    payloadWeight: number;
    currentFuel: number;
    aircraftType: string;
}

function Overview() {

    const userContext = useContext(UserContext);
    
    const [ status, setStatus ] = useState<ActiveFlightStatus>(
        {
            currentLat: 0,
            currentLong: 0,
            flightStatus: 0,
            flightStatusText: '---',
            fromIcao: '----',
            toIcao: '----',
            trueHeading: 0,
            aircraftType: '---',
            currentFuel: 0,
            payloadWeight: 0
        }
    );

    const [ telem, setTelem ] = useState("AWAIT");

    async function update() {

        if (!userContext.WebKey) {
            setTelem('FAULT - RE-LOG');
            return;
        }
        
        const result = await fetch(process.env.REACT_APP_API_HOST + "/flightstatus/active", {
            headers: {
                'Content-Type': 'application/json',
                'X-VirtOps-WEB-Key': userContext.WebKey
            },
            method: 'GET'
        });

        if (!result.ok) {
            if (result.status === 500 || result.status === 404) {
                setTelem("FAULT - API SERV");
            } else if (result.status === 400) {
                setTelem("FAULT - REFRESH");
            } else if (result.status === 401) {
                setTelem("FAULT - AUTH");
            } else {
                setTelem("FAULT - GENERAL " + result.status);
            }
        }
        else {
            const data = await result.json();
            setStatus(data as ActiveFlightStatus);
            setTelem("OK");
        }
    }

    useEffect(() => {
        update().then(() => {});

        const interval = setInterval(() => {
            update().then(() => {});
        }, 2000);
        return () => clearInterval(interval);

    }, [userContext.WebKey]);

    useEffect(() => {
        const navvy = navigator as any;
        if (navvy.wakeLock) {
            navvy.wakeLock.request('screen');
        }
    }, []);

    return <>
        <div className="efb-container">
            <div className="container__row">
                <p className="efb-heading">VIRTOPS OVERVIEW</p>
            </div>
            <div className="container__row">
                <div className="column-2">
                    <div className="element">
                        <div className="element__heading">
                            FROM/TO
                        </div>
                        <div className="element__data">
                            {status.fromIcao}/{status.toIcao}
                        </div>
                    </div>
                    <div className="element">
                        <div className="element__heading">
                            AIRCRAFT
                        </div>
                        <div className="element__data">
                            {status.aircraftType?.toUpperCase() || 'UNKNOWN'}
                        </div>
                    </div>
                    <div className="element">
                        <div className="element__heading">
                            TAIL/ID
                        </div>
                        <div className="element__data">
                            UNKNOWN
                        </div>
                    </div>

                    <div className="element">
                        <div className="element__heading">
                            TELEM STATE
                        </div>
                        <div className="element__data">
                            {telem}
                        </div>
                    </div>
                </div>
                <div className="column-2">
                    <div className="element element--right">
                        <div className="element__heading">
                            FLIGHT STATUS
                        </div>
                        <div className="element__data">
                            {status.flightStatusText.toUpperCase()}
                        </div>
                    </div>
                    <div className="element element--right">
                        <div className="element__heading">
                            PAYLOAD WGT
                        </div>
                        <div className="element__data">
                            {Math.round(status.payloadWeight * 100) / 100}
                        </div>
                    </div>
                    <div className="element element--right">
                        <div className="element__heading">
                            FUEL GAL
                        </div>
                        <div className="element__data">
                            {Math.round(status.currentFuel * 100) / 100}
                        </div>
                    </div>
                    <a className="efb-link" href="/efb/cargo">
                        <div className="element element--right">
                            <div className="element__option">
                                CARGO>
                            </div>
                        </div>
                    </a>
                    <a className="efb-link" href="#">
                        <div className="element element--right">
                            <div className="element__option element__option--abort">
                                CANCEL>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </>
}

export function Efb() {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let { path, url } = useRouteMatch();

    return <>
        <BrowserRouter>
            <Switch>
                <Route path={`${url}/cargo`}>
                    <b>Cargo</b>
                </Route>
                <Route path={`${url}`}>
                    <Overview />
                </Route>

            </Switch>
        </BrowserRouter>
    </>
}