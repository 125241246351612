import React from "react";
import { Link } from "react-router-dom";

export function Home()
{
    return <>
        <div className="home-page">
            <h1>
                VIRTOPS
            </h1>
            <h2>Virtual Airline Operations</h2>
            <p className="home__para">
                the anti-faff approach to operating and flying within a virtual airline. Earn money flying
                personal flights or within a company. Complete special event flights around the world to earn bonuses.
            </p>
            <div>
                <h3>1. Pre-flight</h3>
                <p className="home__para">
                    Load your aircraft with fuel and cargo, and set your flight plan, all from within your sim cockpit.
                </p>
            </div>
            <div>
                <h3>2. Take off</h3>
                <p className="home__para">
                    Fly any aircraft, from any airport, for any duration.
                </p>
            </div>
            <div>
                <h3>3. Land</h3>
                <p className="home__para">
                    Pay for the fuel you've used. Get money for the cargo you hauled, with a bonus for being
                    on-time.
                </p>
            </div>
            <div>
                <h3>Sign up now</h3>
                <Link className="link link--featured" to="/register">Create an account</Link>
            </div>
            <div className="alpha-banner-container">
                <div className="alpha-banner">ALPHA</div>
            </div>
        </div>
    </>;
}