import React, { useEffect, useState } from 'react';
import './App.css';
//import 'leaflet-rotatedmarker';
import { FlightData, FlightDataResponse } from "./Data";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Verify } from "./pages/Verify";
import { FlightMap } from "./pages/FlightMap";
import { Login } from './pages/Login';
import { CreateUserContext, UserContext } from "./context/UserContext";
import { Header } from "./components/Header";
import { Register } from './pages/Register';
import { Home } from './pages/Home';
import { Footer } from "./components/Footer";
import { Dashboard } from "./pages/Dashboard";
import { CookieAutoLogin } from "./components/CookieAutoLogin";
import { Efb } from "./Efb";

function App() {

    const getDefaultFlight = () : FlightData => {
        return {
            position: [51.505, -0.09],
            fromIcao: "",
            trueHeading: 20,
            toIcao: "",
            flightStatus: 9999
        }
    }

    const [flight, setFlight] = useState(getDefaultFlight());

    const getFlightData = async () => {
        const result = await fetch(process.env.REACT_APP_API_HOST + '/flightstatus/F1');
        const data = await result.json() as unknown as FlightDataResponse;

        console.log(data);

        setFlight({
            position: [data.currentLat, data.currentLong],
            trueHeading: data.trueHeading,
            toIcao: data.toIcao,
            fromIcao: data.fromIcao,
            flightStatus: data.flightStatus
        });
    }

    useEffect(() => {
        //setInterval(getFlightData, 5000);
        //getFlightData().then(() => {});
    }, [])

    return (
        <UserContext.Provider value={CreateUserContext()}>
            <BrowserRouter>
                <CookieAutoLogin />
                <div className="App">
                    <Header />
                    <Switch>
                        <Route path="/verify">
                            <Verify />
                        </Route>
                        <Route path="/map">
                            {/*<FlightMap flight={flight} />*/}
                        </Route>
                        <Route path="/dashboard">
                            <Dashboard />
                        </Route>
                        <Route path="/efb">
                            <Efb />
                        </Route>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/register">
                            <Register />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                    <Footer />
                </div>
            </BrowserRouter>
        </UserContext.Provider>
    );
}

export default App;
