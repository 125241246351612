import React, { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { useCookies } from "react-cookie";

export function CookieAutoLogin() {

    const userContext = useContext(UserContext);
    const [cookies] = useCookies(['usercreds']);

    useEffect(() => {
        const user = cookies['usercreds'];
        if (user) {
            console.log('Logging in existing user', user);
            userContext.Login(user.pilotName, user.webKey, user.apiKey);
        }
    }, [cookies]);

    return <></>;
}